import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../../atoms/Button/Button';
import { css } from '@emotion/react';
import Text from '../../atoms/Text/Text';

export default function LibroForm({ bgColorInput, bgColorSubmit }) {
  const formGroupCss = css`
    padding-left: calc(100vw * (calc(5 / var(--width_base))));
    padding-right: calc(100vw * (calc(5 / var(--width_base))));

    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
    }

    &.form-group {
      @media (max-width: 767px) {
        margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
      }
    }

    & select {
      background-position: top calc(100vw * (calc(18 / var(--width_base))))
        right calc(100vw * (calc(12 / var(--width_base))));
      @media (max-width: 767px) {
        background-position: top calc(100vw * (calc(13 / var(--width_base))))
          right calc(100vw * (calc(12 / var(--width_base))));
      }
    }
  `;

  const formAceptCss = css`
    color: white;
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    width: 100%;
    margin-top: calc(100vw * (calc(10 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(10 / var(--width_base))));

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
    }

    & input {
      margin-right: calc(100vw * (calc(10 / var(--width_base))));
    }

    &.form-acept {
      @media (max-width: 767px) {
        margin-left: initial;
      }
    }
  `;

  const buttonCss = css`
    width: 100%;
    margin-top: 0;
    @media (max-width: 767px) {
      margin-top: 0;
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    padding-left: calc(100vw * (calc(5 / var(--width_base))));
    padding-right: calc(100vw * (calc(5 / var(--width_base))));
    padding-top: calc(100vw * (calc(15 / var(--width_base))));
    margin-top: calc(100vw * (calc(20 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    clear: both;
    color: #93ba1f;
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
      margin-top: calc(100vw * (calc(10 / var(--width_base))));
    }
  `;
  const boldText = css`
    font-weight: bold;
    text-decoration: underline;
  `;
  const mensajeCss = css`
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    color: white;
    margin-top: calc(100vw * (calc(15 / var(--width_base))));
  `;

  const [envio, setEnvio] = useState('');

  return (
    <div>
      <Formik
        initialValues={{
          Nombres: '',
          Apellidos: '',
          TipoDocumento: '',
          NroDocumento: '',
          Telefono: '',
          Correo: '',
          NombreEmpresa: '',
          PadreOMadre: '',
          Direccion: '',
          ProductoServicio: '',
          MontoReclamo: '',
          Descripcion: '',
          QuejaReclamo: '',
          DetalleReclamo: '',
          PedidoDelReclamo: '',
          Acepto_condiciones: '',
          FechaDeComunicacion: '',
        }}
        validate={(values) => {
          const errors = {};
          const regexNumber = /^[0-9]+$/;
          if (!values.Nombres) errors.Nombres = 'Campo requerido';
          if (!values.Apellidos) errors.Apellidos = 'Campo requerido';
          if (!values.TipoDocumento) errors.TipoDocumento = 'Campo requerido';
          if (!values.NroDocumento) errors.NroDocumento = 'Campo requerido';
          else if (!values.NroDocumento.match(regexNumber))
            errors.NroDocumento = 'Solo numeros';
          if (!values.Telefono) errors.Telefono = 'Campo requerido';
          if (!values.Correo) errors.Correo = 'Campo requerido';
          else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Correo)
          )
            errors.Correo = 'Correo Invalido';
          if (!values.Direccion) errors.Direccion = 'Campo requerido';
          if (!values.ProductoServicio)
            errors.ProductoServicio = 'Campo requerido';
          if (!values.MontoReclamo) errors.MontoReclamo = 'Campo requerido';
          if (!values.Descripcion) errors.Descripcion = 'Campo requerido';
          if (!values.QuejaReclamo) errors.QuejaReclamo = 'Campo requerido';
          if (!values.DetalleReclamo) errors.DetalleReclamo = 'Campo requerido';
          if (!values.PedidoDelReclamo)
            errors.PedidoDelReclamo = 'Campo requerido';
          if (!values.FechaDeComunicacion)
            errors.FechaDeComunicacion = 'Campo requerido';
          if (!values.Acepto_condiciones)
            errors.Acepto_condiciones = 'Campo requerido';
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          try {
            fetch(
              `${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/codReclamo/v1/codReclamo4`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
              .then((res) => res.json())
              .then((json) => {
                let codReclamo = json;
                let today = new Date();
                let year = today.getFullYear();
                let ceros = '';
                if (codReclamo >= 0 && codReclamo <= 9) ceros = '00';
                if (codReclamo >= 10 && codReclamo <= 99) ceros = '0';
                let newcodigo = ceros + codReclamo + '-' + year;

                const formId = 1331;
                const formData = new FormData();
                formData.append('_wpcf7_unit_tag', `wpcf7-f${formId}-o1`);
                formData.append('CodReclamo', newcodigo);
                formData.append('Nombres', values.Nombres);
                formData.append('Apellidos', values.Apellidos);
                formData.append('TipoDocumento', values.TipoDocumento);
                formData.append('NroDocumento', values.NroDocumento);
                formData.append('Telefono', values.Telefono);
                formData.append('Correo', values.Correo);
                formData.append('Mensaje', values.Mensaje);
                formData.append('NombreEmpresa', values.NombreEmpresa);
                formData.append('PadreOMadre', values.PadreOMadre);
                formData.append('Direccion', values.Direccion);
                formData.append('ProductoServicio', values.ProductoServicio);
                formData.append('MontoReclamo', values.MontoReclamo);
                formData.append('Descripcion', values.Descripcion);
                formData.append('QuejaReclamo', values.QuejaReclamo);
                formData.append('DetalleReclamo', values.DetalleReclamo);
                formData.append('PedidoDelReclamo', values.PedidoDelReclamo);
                formData.append(
                  'FechaDeComunicacion',
                  values.FechaDeComunicacion
                );

                fetch(
                  `${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/1331/feedback`,
                  {
                    method: 'POST',
                    body: formData,
                  }
                )
                  .then((res) => res.json())
                  .then((json) => {
                    if (json.status === 'mail_sent') {
                      setEnvio(true);
                      setSubmitting(false);
                      resetForm();
                    } else {
                      setEnvio(false);
                    }
                  });
              });
          } catch (error) {
            console.error(error);
            setEnvio(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Text
              data="1.- IDENTIFICACIÓN DEL CONSUMIDOR RECLAMANTE"
              colorText="white"
              styleCss={textCss}
              data-aos="fade-right"
              data-aos-delay="300"
            />
            <div className="form-group column column-50" css={formGroupCss}>
              <Field
                className="form-control"
                type="text"
                name="Nombres"
                id="Nombres"
                placeholder="Nombres*"
              />
              <ErrorMessage
                className="form-error"
                name="Nombres"
                component="div"
              />
            </div>
            <div className="form-group column column-50" css={formGroupCss}>
              <Field
                className="form-control"
                type="text"
                name="Apellidos"
                id="Apellidos"
                placeholder="Apellidos*"
              />
              <ErrorMessage
                className="form-error"
                name="Apellidos"
                component="div"
              />
            </div>
            <div className="form-group column column-50" css={formGroupCss}>
              <Field name="TipoDocumento" as="select" className="form-control">
                <option value="">Tipo de documento*</option>
                <option value="DNI">DNI</option>
                <option value="PASAPORTE">PASAPORTE</option>
                <option value="RUC">RUC</option>
                <option value="CARNET EXTRANJERIA">Carnet Extranjería</option>
              </Field>
              <ErrorMessage
                className="form-error"
                name="TipoDocumento"
                component="div"
              />
            </div>
            <div className="form-group column column-50" css={formGroupCss}>
              <Field
                className="form-control"
                type="text"
                name="NroDocumento"
                placeholder="Nro de documento*"
              />
              <ErrorMessage
                className="form-error"
                name="NroDocumento"
                component="div"
              />
            </div>
            <div className="form-group column column-50" css={formGroupCss}>
              <Field
                className="form-control"
                type="text"
                name="Telefono"
                id="Telefono"
                placeholder="Teléfono*"
              />
              <ErrorMessage
                className="form-error"
                name="Telefono"
                component="div"
              />
            </div>
            <div className="form-group column column-50" css={formGroupCss}>
              <Field
                className="form-control"
                type="email"
                name="Correo"
                id="Correo"
                placeholder="Correo*"
              />
              <ErrorMessage
                className="form-error"
                name="Correo"
                component="div"
              />
            </div>
            <div className="form-group column column-50" css={formGroupCss}>
              <Field
                className="form-control"
                type="text"
                name="NombreEmpresa"
                id="NombreEmpresa"
                placeholder="NombreEmpresa"
              />
              <ErrorMessage
                className="form-error"
                name="NombreEmpresa"
                component="div"
              />
            </div>
            <div className="form-group column column-50" css={formGroupCss}>
              <Field
                className="form-control"
                type="text"
                name="PadreOMadre"
                id=""
                placeholder="Padre o Madre (Si es menor de edad)"
              />
              <ErrorMessage
                className="form-error"
                name="PadreOMadre"
                component="div"
              />
            </div>
            <div className="form-group column column-100" css={formGroupCss}>
              <Field
                className="form-control"
                as="textarea"
                name="Direccion"
                placeholder="Dirección"
              />
              <ErrorMessage
                className="form-error"
                name="Direccion"
                component="div"
              />
            </div>
            <Text
              data="2.- IDENTIFICACIÓN DEL BIEN CONTRATADO"
              colorText="white"
              styleCss={textCss}
              data-aos="fade-right"
              data-aos-delay="400"
            />
            <div className="form-group column column-50" css={formGroupCss}>
              <label
                htmlFor="Producto"
                className="form-label"
                css={formAceptCss}
              >
                <Field
                  className="form-control"
                  type="radio"
                  name="ProductoServicio"
                  id="Producto"
                  value="Producto"
                />
                Producto
              </label>
              <label
                htmlFor="Servicio"
                className="form-label"
                css={formAceptCss}
              >
                <Field
                  className="form-control"
                  type="radio"
                  name="ProductoServicio"
                  id="Servicio"
                  value="Servicio"
                />
                Servicio
              </label>
              <ErrorMessage
                className="form-error"
                name="ProductoServicio"
                component="div"
              />
            </div>
            <div className="form-group column column-50" css={formGroupCss}>
              <Field
                className="form-control"
                type="text"
                name="MontoReclamo"
                id="MontoReclamo"
                placeholder="Monto reclamo"
              />
              <ErrorMessage
                className="form-error"
                name="MontoReclamo"
                component="div"
              />
            </div>
            <div className="form-group column column-50" css={formGroupCss}>
              <Field
                className="form-control"
                as="textarea"
                name="Descripcion"
                placeholder="Descripción"
              />
              <ErrorMessage
                className="form-error"
                name="Descripcion"
                component="div"
              />
            </div>
            <Text
              data="3.- DETALLE DE LA RECLAMACIÓN Y PEDIDO DEL CONSUMIDOR"
              colorText="white"
              styleCss={textCss}
              data-aos="fade-right"
              data-aos-delay="200"
            />
            <div className="form-group column column-100" css={formGroupCss}>
              <label htmlFor="Queja" className="form-label" css={formAceptCss}>
                <Field
                  className="form-control"
                  type="radio"
                  name="QuejaReclamo"
                  id="Queja"
                  value="Queja: Disconformidad no relacionada a los productos o servicios; o, malestar o descontento respecto a la atención al público."
                />
                Queja: Disconformidad no relacionada a los productos o
                servicios; o, malestar o descontento respecto a la atención al
                público.
              </label>
              <label
                htmlFor="Reclamo"
                className="form-label"
                css={formAceptCss}
              >
                <Field
                  className="form-control"
                  type="radio"
                  name="QuejaReclamo"
                  id="Reclamo"
                  value="Reclamo: Disconformidad relacionada a los productos y servicios."
                />
                Reclamo: Disconformidad relacionada a los productos y servicios.
              </label>
              <ErrorMessage
                className="form-error"
                name="QuejaReclamo"
                component="div"
              />
            </div>
            <div className="form-group column column-100" css={formGroupCss}>
              <Field
                className="form-control"
                as="textarea"
                name="DetalleReclamo"
                placeholder="Detalle reclamo"
              />
              <ErrorMessage
                className="form-error"
                name="DetalleReclamo"
                component="div"
              />
            </div>
            <div className="form-group column column-100" css={formGroupCss}>
              <Field
                className="form-control"
                as="textarea"
                name="PedidoDelReclamo"
                placeholder="Pedido del reclamo"
              />
              <ErrorMessage
                className="form-error"
                name="PedidoDelReclamo"
                component="div"
              />
            </div>
            <Text
              data="4.- OBSERVACIONES Y ACCIONES ADOPTADAS POR EL PROVEEDOR"
              colorText="white"
              styleCss={textCss}
              data-aos="fade-right"
              data-aos-delay="300"
            />
            <div className="form-group column column-50" css={formGroupCss}>
              <Field
                className="form-control"
                type="date"
                name="FechaDeComunicacion"
                id="FechaDeComunicacion"
                placeholder="Fecha de Comunicación"
              />
              <ErrorMessage
                className="form-error"
                name="FechaDeComunicacion"
                component="div"
              />
            </div>
            <div className="form-group column column-100" css={formGroupCss}>
              <label
                className="form-acept"
                css={formAceptCss}
                htmlFor="Acepto_condiciones"
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Acepto_condiciones"
                  id="Acepto_condiciones"
                />
                &nbsp;&nbsp;&nbsp;Acepto{' '}
                <a
                  css={boldText}
                  href="/terminos-y-condiciones/"
                  target="_blank"
                  rel="noreferrer"
                >
                  política de privacidad
                </a>
                .
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones"
                component="div"
              />
            </div>
            <div className="text-center" css={formGroupCss}>
              <Button
                typeEle="submit"
                styleTypeEle="submit"
                disabled={isSubmitting}
                styleEle={buttonCss}
              >
                ENVIAR
              </Button>
            </div>
            {envio === false && envio !== '' && (
              <div css={mensajeCss}>Error en el envio</div>
            )}
            {envio === true && envio !== '' && (
              <div css={mensajeCss}>Correo enviado</div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
